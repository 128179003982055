/*==========================
Message Box
===========================*/

.message-box {
  position: relative;
  padding: 25px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 75px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.message-box p {
  color: inherit;
  font-size: 16px;
  line-height: 25px;
}

.message-box a {
  color: inherit;
  text-decoration: underline;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.message-box .close-btn {
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 80px;
  font-size: 24px;
  text-align: center;
  display: -webkit-box;
  color: inherit;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.close_icon:before{
  position: relative;
  left: 10px;
  content: "\f175";
  height: 17px;
  width: 1px;
  font-size: 11px;
  font-weight: 700;
  font-family: 'Flaticon';
}

/* Message Info */
.message-box.info{background-color: #CDE9F6; color: #4780AA; }

/* Message warning */
.message-box.warning {background-color: #F7F3D7; color: #927238; }

/* Message success */
.message-box.success {background-color: #DEF2D7; color: #5B7052; }

/* Message Error */
.message-box.error {background-color: #ECC8C5; color: #AB3331 }