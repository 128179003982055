.modal {
	z-index: 10001 !important;
	position: absolute !important;
	width: 100vw !important;
	height: 100vh !important;
	max-width: none !important;

	.dialog {
		background: #ffffff !important;
		border-radius: 8px !important;
		box-shadow: 0px 6px 15px rgb(64 79 104 / 5%) !important;
	}

	.header {
		border-bottom: 1px solid #ecedf2;
		padding: 15px 25px;
	}

	.body {
		padding: 15px 25px;
		max-height: 77vh;
		overflow: auto;

		p {
			font-size: 14px;
			margin-top: 4px !important;
		}
	}

	.footer {
		padding: 15px 25px;
		border-top: 1px solid #ecedf2;
	}
}

.buttonsWrapper {
	display: flex;
	width: 100%;
	.backBtn {
		color: #1967d2;
		border: 1px solid #d4d2d0;
		font-size: 15px;
		line-height: 20px;
		border-radius: 8px;
		font-weight: 500;
		padding: 15px 30px;
		display: flex;
		align-items: center;
		gap: 5px;

		&:hover {
			background-color: #f2f8ff;
			border-color: #1967d2;
		}
	}

	.nextBtn {
		margin-left: auto;
		font-weight: 500;
		padding: 18px 35px;
	}
}

.spinner {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 200px;
}

.audio {
	width: 100%;
	max-width: 600px;
	display: flex;
	margin: auto;
}

.transcriptWrapper {
	margin-top: 15px;
}

.paragraph * {
	font-size: 15px;
}

.paragraph span {
	cursor: pointer;
}

.editBtn {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background: rgba(25, 103, 210, 0.07);
	border-radius: 8px;
	height: 21px;
	width: 21px;
	transition: all 300ms ease;
	color: #1967d2;

	svg {
		width: 13px;
	}

	&:hover {
		background: #1967d2;
		color: #ffffff;
	}
}

.editInputWrapper {
	display: flex;
	align-items: center;
	border: 2px solid #1967d242;
	border-radius: 20px;
	width: 100%;
	max-width: 300px;
	overflow: hidden;
	input {
		width: 100%;
		padding: 6px 12px;
	}

	span {
		cursor: pointer;
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background: #1967d212;
		height: 36px;
		width: 50px;
		transition: all 300ms ease;
		color: #1967d2;
		svg {
			font-size: 18px;
		}

		&:hover {
			background: #1967d2;
			color: #ffffff;
		}
	}
}
