/*==========================
Progress Bars
============================*/

.bar-item {
  position: relative;
  margin-bottom: 48px;
}

.bar-item .skill-bar {
  position: relative;
  width: 100%;
  height: 10px;
}

.bar-item .skill-bar .bar-inner {
  position: relative;
  width: 100%;
  height: 10px;
  background: #D4E1F6;
  border-radius: 5px;
}

.bar-item .skill-bar .bar-inner .bar {
  position: relative;
  left: 0px;
  top: 0px;
  height: 10px;
  width: 0px;
  border-radius: 10px;
  border-bottom: 10px solid #1967D2;
  -webkit-transition: all 2000ms ease;
  -o-transition: all 2000ms ease;
  transition: all 2000ms ease;
}

.bar-item .skill-header {
  position: relative;
  margin-bottom: 12px;
}

.bar-item .skill-header .skill-title {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #5c6770;
}

.bar-item .skill-percentage {
  position: absolute;
  right: 0;
  bottom: 100%;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  color: #202124;
  margin-bottom: 5px;
}