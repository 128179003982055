/*======================
Checkbox 
=======================*/

.checkbox-outer {
  position: relative;
  margin-bottom: 30px;
}

.checkboxes{
  list-style: none;
  padding: 0;
}

.checkboxes.inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.checkboxes li {
  margin-right: 20px;
  margin-bottom: 10px;
}

.checkboxes label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 20px;
  color: #696969;
}

.checkboxes label span {
  color: #909090;
}

.checkboxes input[type="checkbox"] {
  display: none;
}

.checkboxes label:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 2px;
  background-color: #fff;
  border: 1px solid #ECEDF2;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  font-family: "Font Awesome 5 Free";
  color: #fff;
  font-size: 9px;
  font-weight: 900;
  text-align: center;
  line-height: 17px;
}

.checkboxes input[type="checkbox"]:checked + label:before {
  content: "\f00c";
  background-color: #1967D2;
  border: 1px solid #1967D2;
}

.checkboxes.square label:before{
  border-radius: 2px;
}

.checkboxes.two-column:after {
  display: table;
  clear: both;
  content: "";
}

.checkboxes.two-column li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  float: left;
  margin: 0;
  min-width: 50%;
  padding-right: 10px;
}

.checkbox-outer .view-more{
  position: relative;
}

.checkbox-outer .view-more{
  font-size: 13px;
  line-height: 20px;
  color: #1967D2;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkbox-outer .view-more .icon{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  font-weight: 900;
  font-size: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background: rgba(25,103,210,.15);
  border-radius: 60px;
}

/*======================
Radio bOX
=======================*/

.radio-box{
  position: relative;
}

.radio-box label{
  position: relative;
  display: block;
  font-size: 15px;
  color: #696969;
  line-height: 20px;
  padding-left: 30px;
  cursor: pointer;
  margin-bottom: 18px;
}

.radio-box label:before{
  position: absolute;
  left: 0;
  top: 0;
  height:16px;
  width: 16px;
  background: #ffffff;
  content: "";
  border: 1px solid #ECEDF2;
  border-radius: 50px;
  background: #ffffff;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.radio-box label:after {
  position: absolute;
  content: "";
  left: 4px;
  top: 4px;
  height: 8px;
  width: 8px;
  border-radius: 5px;
  overflow: hidden;
  background: transparent;
  text-align: center;
}

.radio-box input[type="radio"]{
  display: none;
}

.radio-box input[type="radio"]:checked + label{
  border-color: #1967D2;
}

.radio-box input[type="radio"] {

}

.radio-box input[type="radio"]:checked + label:before{
  border-color: #1967D2;
}

.radio-box input[type="radio"]:checked + label:after {
  background-color: #1967D2;
  opacity: 1; 
}

/*======================
Switches 
=======================*/

.switchbox-outer {
  position: relative;
}

.switchbox-outer h4 {
  font-size: 16px;
  line-height: 1em;
  color: #1b2032;
  font-weight: 700;
  margin-bottom: 15px;
}

.switchbox {
  position: relative;
}

.switchbox li {
  position: relative;
  margin-bottom: 20px;
}

.switchbox .title {
  position: relative;
  margin-left: 10px;
  font-size: 15px;
  color: #5c6770;
  line-height: 20px;
}

.switch {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 20px;
  background-color: #ffffff;
  border: 1px solid #ECEDF2;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 3px;
  background-color: #D2D3D8;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1967D2;
  border: 2px solid #1967D2;
}

input:checked + .slider:before {
  background: #ffffff;
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}





/*=== Default Form ===*/

.default-form {
  position: relative;
}

.default-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.default-form .form-group:last-child {
  margin-bottom: 0;
}

.default-form .form-group > label {
  font-size: 15px;
  line-height: 20px;
  color: #202124;
  font-weight: 500;
  margin-bottom: 10px;
}

.default-form .form-group input[type="text"],
.default-form .form-group input[type="password"],
.default-form .form-group input[type="number"],
.default-form .form-group input[type="email"],
.default-form .form-group input[type="url"],
.default-form .form-group textarea,
.default-form .form-group select {
  position: relative;
  width: 100%;
  display: block;
  height: 60px;
  line-height: 30px;
  padding: 15px 20px;
  font-size: 15px;
  color: #696969;
  background: #F0F5F7;
  border: 1px solid #F0F5F7;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.default-form .form-group input[type="text"]:focus,
.default-form .form-group input[type="email"]:focus,
.default-form .form-group input[type="url"]:focus,
.default-form .form-group select:focus,
.default-form .form-group textarea:focus {
  border-color: #1967D2;
  background: #ffffff;
}

.default-form .form-group textarea {
  height: 160px;
  resize: none;
  padding-top: 15px;
}

.default-form .form-group input[type="submit"],
.default-form .form-group >button {
  position: relative;
  display: inline-block;
  min-width: 160px;
}

.default-form input.error:focus,
.default-form select.error:focus,
.default-form textarea.error:focus {
  border-color: #ff0000;
}

.default-form label.error {
  display: block;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  line-height: 24px;
  color: #ff0000;
  margin-bottom: 0;
}

.default-form .form-group >.icon{
  position: absolute;
  right: 20px;
  bottom: 0;
  color: #9fa9b8;
  font-size: 14px;
  line-height: 54px;
}

.default-form .form-group .rating{
  position: relative;
}

.default-form .form-group .rating a{
  position: relative;
  font-size: 13px;
  color: #999999;
}
.default-form .form-group .rating a:hover{
  color: #FFC78B;
}

#email-form .form-group:first-child{
  margin-bottom: 0;
}

#email-form .response {
  position: relative;
  text-align: center;
}

#email-form .response .success {
  background: #35c2a5;
  color: #ffffff;
  padding: 0 10px;
  margin-bottom: 10px;
}

#email-form .response .failed {
  background-color: #f74848;
  color: #ffffff;
  padding: 0 10px;
  margin-bottom: 10px;
}

#email-form .response .text-info {
  background-color: #ffa737;
  color: #222222 !important;
  padding: 0 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chosen-container-multi .chosen-choices li.search-field input[type=text],
.chosen-container-active .chosen-choices li.search-field input[type=text]{
  line-height: 60px !important;
  min-height: 60px !important;
}

.chosen-container-multi .chosen-choices,
.chosen-container.chosen-with-drop .chosen-drop{
  background: #F0F5F7;
  min-height: 60px;
  line-height: 60px;
  border: 0 !important;
  border-radius: 8px;
}

.chosen-container-single .chosen-single{
  background: #F0F5F7;
  border: 0 !important;
  border-radius: 8px;
}

.chosen-container-single .chosen-search:before{
  display: none;
}

.chosen-container-multi .chosen-choices li.search-choice{
  color: rgba(25, 103, 210, 1);
  font-size: 13px;
  line-height: 25px;
  color: #1967D2;
  padding: 0 25px 0 10px;
  border-radius: 10px;
  background: rgba(25, 103, 210, .1);
}

.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:before{
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  top: 3px;
  color: rgba(25, 103, 210, 1);
}

.chosen-container-multi .chosen-choices li.search-choice{
  margin-top: 15px;
}

.chosen-container-single .chosen-search input[type=text]{
  height: 40px;
  line-height: 20px;
  padding: 10px 20px;
}
