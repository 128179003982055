/*==========================
Banner Box One
===========================*/

.banner-box-one {
  position: relative;
  margin-bottom: 30px;
}

.banner-box-one .cws-image-bg {
  -webkit-clip-path: polygon(0% 20px, 100% 0%, 100% calc(100% - 20px), 0% 100%);
  clip-path: polygon(0% 20px, 100% 0%, 100% calc(100% - 20px), 0% 100%);
}

.banner-box-one .cws-image-bg .cws-overlay-bg {
  width: 50%;
  background-color: #529b6b;
}

.banner-box-one .cws-triangle-overlay {
  opacity: 0.3;
  -webkit-clip-path: polygon(
      0% 0,
      50% 10px,
      100% 0,
      100% 100%,
      50% calc(100% - 10px),
      0 100%
      );
  clip-path: polygon(
      0% 0,
      50% 10px,
      100% 0,
      100% 100%,
      50% calc(100% - 10px),
      0 100%
      );
  background-color: #529b6b;
}

.banner-box-one .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 21px;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 245px;
  z-index: 1;
}

.banner-box-one .content {
  width: 50%;
  text-align: center;
}

.banner-box-one .banner-offer {
  position: relative;
  display: block;
  color: #ffffff;
  font-size: 55px;
  line-height: 1;
  margin-bottom: 5px;
}

.banner-box-one .banner-desc {
  display: block;
  font-size: 15px;
  margin-bottom: 5px;
  opacity: 0.7;
  color: #ffffff;
}

.banner-box-one.orange .cws-triangle-overlay {
  background: #ed6436;
}

.banner-box-one.orange .cws-overlay-bg {
  background-color: #ed6436;
}

.banner-box-one.orange .cws-image-bg:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(237, 100, 54, 0.65);
  content: "";
  z-index: 1;
}

.banner-box-one.orange .theme-btn {
  border-color: #ffffff;
}

/*==========================
Banner Box Two
===========================*/

.banner-box-two {
  position: relative;
  margin-bottom: 30px;
}

.banner-box-two .cws-image-bg {
  -webkit-clip-path: none;
  clip-path: none;
}

.banner-box-two .cws-image-bg .cws-overlay-bg {
  background-color: rgba(75, 180, 105, 0.9);
}

.banner-box-two .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 21px;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 245px;
  z-index: 1;
}

.banner-box-two .content {
  width: 100%;
  text-align: center;
}

.banner-box-two .banner-title {
  margin-bottom: 0;
  font-size: 30px;
  text-transform: uppercase;
  color: #fff;
}

.banner-box-two .banner-desc {
  display: block;
  font-size: 15px;
  margin-bottom: 5px;
  opacity: 0.7;
  color: #ffffff;
}

.banner-box-two.dark .cws-overlay-bg {
  background-color: rgba(0, 0, 0, 0.85);
}

.banner-box-two.dark .theme-btn {
  border-color: #ffffff;
}

/*==========================
Banner Box Three
===========================*/

.banner-box-three {
  position: relative;
  margin-bottom: 30px;
}

.banner-box-three .cws-image-bg {
  -webkit-clip-path: polygon(0% 20px, 100% 0%, 100% 100%, 0% calc(100% - 20px));
  clip-path: polygon(0% 20px, 100% 0%, 100% 100%, 0% calc(100% - 20px));
}

.banner-box-three .cws-image-bg:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(81, 158, 107, 0.6);
  content: "";
}

.banner-box-three .cws-image-bg .cws-overlay-bg {
  width: 50%;
  background-color: #ffa737;
}

.banner-box-three .cws-triangle-overlay {
  opacity: 0.3;
  -webkit-clip-path: polygon(
      0% 0,
      50% 10px,
      100% 0,
      100% 100%,
      50% calc(100% - 10px),
      0 100%
      );
  clip-path: polygon(
      0% 0,
      50% 10px,
      100% 0,
      100% 100%,
      50% calc(100% - 10px),
      0 100%
      );
  background-color: #529b6b;
}

.banner-box-three .inner-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 21px;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 245px;
  z-index: 1;
}

.banner-box-three .content {
  width: 50%;
}

.banner-box-three .banner-title {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 1.2em;
  text-transform: uppercase;
  color: #fff;
}

.banner-box-three .banner-desc {
  display: block;
  font-size: 15px;
  margin-bottom: 5px;
  opacity: 0.7;
  color: #ffffff;
}

.banner-box-three .banner-offer {
  position: relative;
  display: block;
  color: #ffffff;
  font-size: 55px;
  line-height: 1;
  margin-bottom: 5px;
}

.banner-box-three.orange .cws-triangle-overlay {
  background: #ed6436;
}

.banner-box-three.orange .cws-overlay-bg {
  background-color: #ff6c3a;
  z-index: 1;
}

.banner-box-three.orange .cws-image-bg:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(237, 100, 54, 0.65);
  content: "";
  z-index: 1;
}

.banner-box-three.orange .theme-btn {
  border-color: #ffffff;
}