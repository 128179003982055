.banner-section.-type-13 {
	background-image: url('../../../images/index-13/header/test.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	&::before {
		display: none;
	}

	.content-column .inner-column {
		padding: 260px 0px 150px;

		@include media-below(md) {
			padding: 140px 0px 120px;
		}
		@include media-below(sm) {
			padding: 120px 0px 100px;
		}
	}

	.title-box {
		h3 {
			color: white;
		}
		.text {
			color: white;
		}
	}

	.theme-btn {
		background-color: #131a51;
		color: white;
		padding: 15px 50px;
		border-radius: 8px;

		&:hover {
			background-color: #1967d2;
			color: white;
		}
	}

	.popular-searches {
		margin-top: 20px !important;
		color: white;

		* {
			color: white !important;
		}
	}

	.bottom-box {
		margin-top: 120px;
		display: flex;
		align-items: center;

		@include media-below(md) {
			margin-top: 60px;
		}

		.title {
			color: white;
			margin-right: 20px;
		}

		.upload-cv {
			color: white;
			margin-left: 98px;

			@include media-below(md) {
				margin-left: 20px;
			}
		}
	}

	.image-column .image-box {
		position: absolute;
		right: -60px;

		.main-image {
			margin-bottom: 0;

			img {
				max-width: 120%;
			}
		}
	}
}

.text-dark-blue {
	color: #131a51 !important;
}

.job-block-five-separated {
	box-shadow: none !important;
	border: none !important;

	> * {
		.inner-box {
			background-color: #f5f7fb !important;
			border: 1px solid #ecedf2;
			border-radius: 8px;

			&:hover {
				border-color: #ecedf2 !important;
			}
		}

		&:hover {
			.inner-box {
				background-color: white !important;
				box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
			}
		}

		+ * {
			margin-top: 30px;
		}
	}
}

.theme-btn.btn-dark-blue {
	padding: 16px 38px;
	border-radius: 8px;
	background-color: rgba(#131a51, 0.07) !important;
	color: #131a51 !important;
	font-size: 15px;
	font-weight: 400;
	line-height: 1;

	&:hover {
		background-color: #131a51 !important;
		color: white !important;
	}
}

.banner-style-two.-type-2 .inner-box,
.banner-style-one.-type-2 .inner-box {
	background-size: cover;
	background-repeat: no-repeat;
}

.switchbox.-horizontal {
	display: flex;
	justify-content: center;
	margin: -8px -15px;

	> * {
		padding: 8px 15px;
	}

	@include media-below(md) {
		flex-wrap: wrap;
	}
}

.work-block.-type-2 {
	.icon-wrap {
		margin: 0 auto;
		width: 111px;
		height: 111px;
		border-radius: 100%;
		font-size: 50px;
		display: flex;
		justify-content: center;
		align-items: center;

		&.-blue {
			background: rgba(#beedff, 0.4);

			.icon::before {
				color: #87deff;
			}
		}

		&.-red {
			background: rgba(#fecbd1, 0.4);

			.icon::before {
				color: #ffa3ad;
			}
		}

		&.-yellow {
			background: rgba(#fddf8e, 0.4);

			.icon::before {
				color: #ffd257;
			}
		}
	}

	h5 {
		margin-top: 29px;
	}

	p {
		margin-top: 13px;
	}
}

.image-box.-type-1 {
	> *:nth-child(2) {
		position: absolute;
		top: 200px;
		left: -80px;
		box-shadow: 0px 40px 30px rgba(25, 25, 46, 0.04);

		@include media-below(lg) {
			display: none;
		}
	}

	> *:nth-child(3) {
		position: absolute;
		top: 50px;
		right: -100px;
		box-shadow: 0px 40px 30px rgba(25, 25, 46, 0.04);

		@include media-below(lg) {
			display: none;
		}
	}

	> *:nth-child(4) {
		position: absolute;
		bottom: -44px;
		left: 115px;
		box-shadow: 0px 40px 30px rgba(25, 25, 46, 0.04);

		@include media-below(lg) {
			display: none;
		}
	}
}

.row.grid-flex {
	margin: -10px;
	display: flex;

	> * {
		padding: 10px;
	}
}

.icon-item.-type-3 {
	display: block;
	background-color: #f5f7fb;
	border: 1px solid transparent;
	border-radius: 120px;
	padding: 10px;
	padding-right: 28px;
	display: flex;
	align-items: center;
	transition: all 0.2s ease;

	.icon-wrap {
		width: 50px;
		height: 50px;
		min-width: 50px;
		min-height: 50px;
		border-radius: 100%;
		background-color: white;
		color: #131a51;
		font-size: 24px;
		transition: all 0.2s ease;
	}

	.content {
		margin-left: 12px;

		h4 {
			font-size: 14px;
			font-weight: 500;
			line-height: 1;
			color: #202124;
		}
	}

	&:hover {
		background-color: white;
		border: 1px solid #ecedf2;
		box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);

		.icon-wrap {
			background-color: #131a51;
			color: white;
		}
	}
}

.main-footer.-type-13 {
	&::before {
		background-image: url('../../../images/index-13/footer/bg.svg');
	}
}

.subscribe-section-two.-type-3 {
	padding-top: 40px;
	padding-bottom: 40px;

	.background-image {
		background-size: cover;
		border-radius: 32px;
		left: 60px;
		right: 60px;

		@media (max-width: 1023px) {
			border-radius: 0;
			left: 0;
			right: 0;
		}
	}

	.sec-title {
		padding-left: 50px;

		@include media-below(md) {
			padding-left: 0;
		}

		h2 {
		}

		.text {
			margin-top: 20px;
		}

		.buttons {
			padding-top: 35px;
			margin: -5px;

			> * {
				padding: 5px;
			}
		}
	}

	.image {
		width: 100%;
		display: flex;
		justify-content: center;

		@include media-below(md) {
			margin-top: 20px;
		}
	}
}

.text-left {
	text-align: left !important;
}

.pricingCard.-type-2 {
	padding-top: 42px;

	@include media-below(md) {
		padding: 30px;
	}

	.pricingCard__title {
		font-size: 18px;
		font-weight: 600;
		line-height: 1;
		color: #131a51;
	}

	.pricingCard__price {
		margin-top: 12px;
		font-size: 40px;
		font-weight: 700;
		line-height: 1;
		color: #131a51;
	}

	.pricingCard__subtitle {
		font-size: 14px;
		font-weight: 400;
		line-height: 26px;
	}

	.pricingCard__text {
		font-size: 14px;
		font-weight: 400;
		line-height: 26px;
		padding-right: 30px;
	}

	.pricingCard__list {
		li + li {
			margin-top: 16px;
		}
	}

	.pricingCard__btn .theme-btn {
		width: 100%;
		border-radius: 8px !important;
		background-color: #e5e8ef !important;
	}

	&:hover {
		background-color: #f5f7fb !important;
		color: unset;

		.pricingCard__title {
			color: #3642ad !important;
		}
		.pricingCard__price {
			color: #3642ad !important;
		}

		.pricingCard__btn .theme-btn {
			background-color: #131a51 !important;
			color: white !important;
		}
	}
}

.color-dark-1 {
	color: #202124 !important;
}

.color-dark-2 {
	color: #696969 !important;
}

.button.-arrow {
	display: flex;
	align-items: center;

	span {
		margin-left: 8px;
	}
}
