.col {
	display: flex;
	flex-direction: column;
	@media (max-width: 599px) {
		align-items: center;
		width: 100%;

		& > div {
			text-align: center;
		}
	}
}

.row {
	display: flex;
	align-items: center;
	gap: 20px;
	@media (max-width: 599px) {
		flex-direction: column;
		align-items: center;
	}
}

.submitBtn {
	display: flex !important;
	margin-left: auto !important;
	margin-bottom: 16px;
}
