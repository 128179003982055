/*======================
Radio bOX
=======================*/
.tooltip-outer{
  position: relative;
}

.tooltip-btn{
  position: relative;
  font-size: 15px;
  color: #696969;
  font-weight: 400;
  line-height: 30px;
  padding: 10px 20px;
  min-width: 100px;
  text-align: center;
  background: #F0F5F7;
  border-radius: 8px;
  text-transform: capitalize;
  margin-bottom: 5px;
}