.spinner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
}

.actionsTD {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	& > div {
		height: 100%;
		margin-top: 10px;
		ul {
			display: flex;
			gap: 8px;
			height: 100%;

			li {
				margin: 0px;
			}
		}
	}
}
