/*** 

====================================================================
Recruiter Section
====================================================================

***/

.recruiter-section{
  position: relative;
}

.recruiter-section .outer-box{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.recruiter-section .image-column{
  position: relative;
  width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.recruiter-section .image-column .image{
  position: relative;
  display: block;
  margin-bottom: 0;
}

.recruiter-section .image-column .image img{
  width: 100%;
  border-radius: 0 30px 30px 0;
}

.recruiter-section .content-column{
  position: relative;
  width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  padding: 100px 0;
}

.recruiter-section .content-column .inner-column{
  position: relative;
  padding-left: 140px;
}

.recruiter-section .content-column .sec-title{
  margin-bottom: 0;
  max-width: 420px;
}

.recruiter-section .content-column .theme-btn{
  margin-top: 18px;
  min-width: 180px;
}