.form-error {
	font-size: 13px !important;
	color: #a9252b !important;
	padding-left: 2px !important;
}

.required {
	color: #a9252b;
}

.select-label {
	font-size: 15px;
	line-height: 20px;
	color: #202124;
	font-weight: 500;
	margin-bottom: 10px;
}

.mt-n1 {
	margin-top: -0.25rem !important;
}

.mt-n2 {
	margin-top: -0.5rem !important;
}

.mt-n3 {
	margin-top: -1rem !important;
}

.mt-n4 {
	margin-top: -1.25rem !important;
}
.mt-n5 {
	margin-top: -1.5rem !important;
}

.center-spinner {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 60%;
}

.default-form .form-group input[type='number']:focus {
	border-color: #1967d2;
	background: #ffffff;
}

.divider {
	content: '';
	display: flex;
	width: 100%;
	height: 1px;
	background-color: #d4d2d0;
}

.bold-500 {
	font-weight: 500;
}
