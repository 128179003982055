/*** 

====================================================================
Shop Single
====================================================================

***/

.shop-single{
  position: relative;
  padding: 60px 0;
}

.product-details{
  position: relative;
  margin-bottom: 50px;
}

.product-details .basic-details{
  position:relative;
  margin-bottom:30px;
}

.product-details .image-column,
.product-details .info-column{
  margin-bottom:50px;
}

.product-details .image-column .image{
  position: relative;
  overflow: hidden;
  background: #ffffff;
  border-radius: 8px;
  padding: 70px 30px;
  border: 1px solid #ECEDF2;
  text-align: center;
}

.product-details .image-column .image img{
  display: inline-block;
}

.product-details .image-column .image .icon{
  position: absolute;
  left: 35px;
  bottom: 35px;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  line-height: 60px;
  font-size: 24px;
  color: #1b2032;
  text-align: center;
  background-color: #f9fafc;
}

.product-details .info-column .inner-column{
  position: relative;
  padding-left: 20px;
}

.product-details .basic-details .product-name{
  font-weight: 500;
  font-size: 30px;
  line-height: 41px;
  color: #202124;
  margin-bottom: 15px;
}

.product-details .basic-details .rating {
  position: relative;
  font-size: 14px;
  line-height: 1em;
  color: #5c6770;
  margin-bottom: 25px;
}

.product-details .basic-details .rating .fa {
  font-size: 14px;
  color: #d0d23c;
  margin-right: 2px;
}

.product-details .basic-details .rating .title {
  margin-left: 3px;
}

.product-details .basic-details .text{
  margin-bottom: 20px;
}

.product-details .basic-details .item-price{
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: #202124;
  margin-bottom: 30px;
}

.product-details .basic-details .item-price del{
  font-size: 14px;
  line-height: 19px;
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
  color: #696969;
  margin-left: 10px;
}

.product-details .other-options{
  position: relative;
}

.product-details .other-options .item-quantity{
  font-size:14px;
  line-height: 50px;
  margin-right: 30px;
  margin-bottom: 20px;
}

.item-quantity .qty{
  position: relative;
  display: inline-block;
  width: 110px;
  padding: 10px 20px;
  height: 50px;
  line-height: 26px;
  color: #5c6770;
  font-size: 15px;
  border-radius: 4px;
  border: 1px solid #ECEDF2;
}

.qty::-webkit-outer-spin-button,
.qty::-webkit-inner-spin-button {
  opacity: 1;
  visibility: visible;
  background: none;
}

.item-quantity .in-stock{
  position: relative;
  padding-left: 25px;
  margin-left: 30px;
}

.item-quantity .in-stock:before{
  position: absolute;
  left: 0;
  top: 0px;
  height: 18px;
  width: 18px;
  background: #696969;
  content: "\f00c";
  border-radius: 10px;
  color: #ffffff;
  font-size: 8px;
  line-height: 18px;
  font-weight: 900;
  text-align: center;
  font-family: 'Font Awesome 5 Free';
}

.product-details .other-options .theme-btn span{
  margin-right: 10px;
}

.product-details .other-options .bookmark-btn{
  padding: 15px 20px;
  margin-left: 20px;
}
.product-details .other-options .bookmark-btn span{
  margin: 0;
}

.product-details .product-meta{
  position: relative;
  margin-top: 30px;
  margin-bottom: 40px;
}

.product-details .product-meta li{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  line-height: 30px;
  color: #696969;
}

.product-details .product-meta li span{
  display: block;
  margin-right: 10px;
  min-width: 90px;
}