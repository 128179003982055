/***

==================================================================
TNC Section
==================================================================

***/

.tnc-section{
  position: relative;
  padding: 100px 0 50px;
}

.tnc-section .auto-container{
  max-width: 880px;
}

.tnc-section .text-box{
  position: relative;
  margin-bottom: 50px;
}

.tnc-section h3{
  font-weight: 500;
  font-size: 26px;
  line-height: 35px;
  color: #202124;
  margin-bottom: 23px;
}

.tnc-section p{
  font-size: 15px;
  line-height: 24px;
  color: #696969;
  margin-bottom: 24px;
}