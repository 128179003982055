/*==========================
Accordian Box
===========================*/

.accordion-box {
  position: relative;
  margin-bottom: 30px;
}

.accordion-box .block {
  position: relative;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #ECEDF2;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.accordion-box .block .acc-btn {
  position: relative;
  font-size: 16px;
  padding: 17px 30px;
  line-height: 30px;
  color: #202124;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.accordion-box .block.active-block {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.accordion-box .block .acc-btn .icon {
  position: absolute;
  right: 0;
  top: 0;
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  color: #2F2D51;
  font-size: 15px;
  font-weight: 700;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.accordion-box .block .acc-btn.active .icon:before{
  content: "\f151";
}

.accordion-box .block .acc-content {
  position: relative;
  display: none;
}

.accordion-box .block .acc-content.current {
  display: block;
}

.accordion-box .block .content {
  position: relative;
  padding: 24px 30px 26px;
  border-top: 1px solid #ECEDF2;
}

.accordion-box .block .content p{
  position: relative;
  font-size: 15px;
  line-height: 24px;
  color: #696969;
  font-weight: 400;
  margin-bottom: 24px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.accordion-box .block .content p:last-child{
  margin-bottom: 0;
}