/*==========================
Preloader
===========================*/

.preloader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 999999;
	background-color: #ffffff;
}

.preloader:after {
	position: absolute;
	left: 50%;
	top: 50%;
	height: 50px;
	width: 50px;
	margin-left: -25px;
	margin-top: -25px;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url(../../images/favicon.png);
	content: '';
}

.preloader:before {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	width: 150px;
	text-align: center;
	margin: 0 auto;
	margin-top: 40px;
	color: #1967d2;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 2px;
	content: 'LOADING';
}
